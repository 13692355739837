<template>
  <div>
    <a-modal
      v-model="visible"
      :confirmLoading="loading"
      :destroyOnClose="true"
      :maskClosable="false"
      centered
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">编辑计费规则</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="number" label="产品编号">
            <a-input :value="form.number" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item prop="name" label="产品名称">
            <a-input :value="form.name" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item prop="charge_unit" label="计费单位">
            <a-select v-model="form.charge_unit" allowClear style="width: 100%">
              <a-select-option key="quantity" value="quantity">件</a-select-option>
              <a-select-option key="ton" value="ton">吨</a-select-option>
              <a-select-option key="cube" value="cube">立方</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="stock_in_transport_unit_price" label="入库配送单价(元)">
            <a-input-number v-model="form.stock_in_transport_unit_price" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="stock_in_handling_unit_price" label="入库卸货单价(元)">
            <a-input-number v-model="form.stock_in_handling_unit_price" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="stock_out_transport_unit_price" label="出库配送单价(元)">
            <a-input-number v-model="form.stock_out_transport_unit_price" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="stock_out_handling_unit_price" label="出库装车单价(元)">
            <a-input-number v-model="form.stock_out_handling_unit_price" style="width: 100%" />
          </a-form-model-item>
          <a-form-model-item prop="storage_unit_price" label="仓储单价(元)">
            <a-input-number v-model="form.storage_unit_price" style="width: 100%" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { chargeRuleUpdate } from "@/api/charge";
import rules from "./rules.js";

export default {
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules,
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          chargeRuleUpdate(this.form)
            .then((data) => {
              this.$message.success("修改成功");
              this.$emit("update", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
